import dayjs from "dayjs"

import updateLocale from "dayjs/plugin/updateLocale"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import routes from "./router/routes"
import {
  AssetGroupModuleType,
  AssetModuleType,
  BackgroundModuleType,
  BasketModuleType,
  CalendarModuleType,
  Click2MapsModuleType,
  CountdownModuleType,
  CounterModuleType,
  DestinationModuleType,
  GestureModuleType,
  GoogleMapsModuleType,
  HotOrNotModuleType,
  LightweightSwiperGroupModuleType,
  MatchModuleType,
  MemoryModuleType,
  OfferistaAssetModuleType,
  OfferistaBannerModuleType,
  OfferistaFlyerAssetModuleType,
  OfferistaModuleType,
  PanoModuleType,
  ParticleWipeAdModuleType,
  PollModuleType,
  PollSliderModuleType,
  PopupModuleType,
  PuzzleModuleType,
  ShakeModuleType,
  SlidebarModuleType,
  SliderModuleType,
  StoryModuleType,
  SurveyModuleType,
  SurveySliderModuleType,
  SwiperGroupModuleType,
  ThreeDModuleType,
  TimerModuleType,
  TypoModuleType,
  VastVideoModuleType,
  VideoControlsModuleType,
  VideoModuleType,
  VideoStoryModuleType,
  WagawinVideoPollModuleType,
  WhatsappModuleType,
  WipeAdModuleType
} from "./components/designer/module_types/types"
import InvalidNotificationException from "./errors/InvalidNotificationException"
import {
  AD_FORMATS,
  AVAILABLE_ONLY_IN_ROOT_SCOPE_MODULES,
  AVAILABLE_ONLY_ONCE_PER_CREATIVE,
  AVAILABLE_ONLY_ONCE_PER_SCENE,
  DEVICE_TYPE_DESKTOP,
  EXPANDABLE_BANNER_FORMATS,
  MAX_VIDEO_CONTROLS_HEIGHT,
  MIN_VIDEO_CONTROLS_HEIGHT,
  NON_DUPLICABLE_MODULES,
  PRODUCT_DOOH,
  PRODUCT_LIVING_ADS,
  PRODUCT_VAST
} from "./constants"
import { ModuleDataFields } from "./moduleDataFields"
import { ModuleDataFields as ModuleDataFieldsDooh } from "./moduleDataFieldsDoohDecorator"
import { ModuleDataFields as ModuleDataFieldsVast } from "./moduleDataFieldsVastDecorator"
import { ModuleDataFields as ModuleDataFieldsDesktop } from "./moduleDataFieldsDesktopDecorator"
import { upperFirst } from "lodash/string"
import isEqual from "lodash/isEqual"
import kebabCase from "lodash/kebabCase"
import { v4 as uuidv4 } from "uuid"
import { EffectType } from "@/components/designer/effect_types/types"

export default class Utils {
  /**
   * Scales a value.
   * @param {number} value
   * @param {number} scale
   * @returns {number}
   */
  static scaleValue (value, scale) {
    return value * scale
  }

  static getBasePhoneDims (design) {
    if (design.useAspectRatios) {
      return [[320, 480], [414, 660], [360, 740]]
    }

    return [[320, 480], [375, 667], [414, 896]]
  }

  /**
   * Gets a cookie by name.
   * @param {string} name
   * @returns {cookie}
   */
  static getCookie (name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()

    return null
  }

  /**
   * Returns page layout class.
   * @returns {string} class
   */
  static getPageLayout () {
    const cleanRoutesRegex = /\/(login|reset|password|invites).*?/i
    return cleanRoutesRegex.test(window.location.pathname) ? "clean" : "spa"
  }

  /**
   * Generates module html id.
   * @param {module} module
   * @returns {string} moduleHtmlId
   */
  static generateModuleHtmlId (module) {
    let newHtmlId = module.uuid.split("-")
    let name = module.name
    name = name.replace(/[\s]/gi, "-")
    name = name.replace(/[\W]/gi, "")
    newHtmlId = "m-" + newHtmlId[newHtmlId.length - 1] + "-" + name

    return newHtmlId.substring(0, 100)
  }

  /**
   * Returns last uuid part
   *
   * @param {module} module
   * @returns {string} moduleHtmlId
   */
  static getLastUuidPart (module) {
    const newHtmlId = module.uuid.split("-")

    return newHtmlId[newHtmlId.length - 1]
  }

  /**
   * Returns range slider width.
   * @param {number} value
   * @param {number} max
   * @param {number} min
   * @returns {number} rangeSliderWidth
   */
  static getRangeSliderWidth (value, max, min) {
    return Math.max(0, ((value - min) / (max - min)) * 100)
  }

  /**
   * Returns range slider width css.
   * @param {number} value
   * @param {number} max
   * @param {number} min
   * @returns {string} rangeSliderWidthCss
   */
  static getRangeSliderWidthCss (value, max, min) {
    let offset = "0px"
    if (value > min) {
      offset =
        (Math.round(this.getRangeSliderWidth(value, max, min)) / 100) * 18 +
        "px"
    }
    return (
      "width: calc(" +
      this.getRangeSliderWidth(value, max, min) +
      "% - " +
      offset +
      ");"
    )
  }

  /**
   * Checks if parent has class
   * @param {HTMLElement} element
   * @param {string} classname
   * @returns {boolean}
   */
  static parentHasClass (element, classname) {
    if (element?.classList?.contains(classname)) {
      return true
    }
    return (
      element.parentNode && this.parentHasClass(element.parentNode, classname)
    )
  }

  /**
   * Returns module icon for given module type
   * @param {String} type
   */
  static getModuleIcon (type) {
    switch (type) {
      case VideoModuleType:
        return "video-event"
      case SwiperGroupModuleType:
      case LightweightSwiperGroupModuleType:
      case OfferistaModuleType:
        return "gallery-event"
      case PopupModuleType:
        return "popup-event"
      case ShakeModuleType:
        return "shake-event"
    }

    return "custom-event"
  }

  /**
   * Clears expired local storage
   */
  static clearExpiredLocalStorage () {
    const items = { ...window.localStorage }

    for (const [key, item] of Object.entries(items)) {
      if (
        key.match(/design_.*/i) !== null && (
          (item.hasOwnProperty("ts") &&
            item.hasOwnProperty("ttl") &&
            item.ts + item.ttl < Date.now()) ||
          item.hasOwnProperty("ts") === false ||
          item.hasOwnProperty("ttl") === false) // Fallback for older designs
      ) {
        window.localStorage.removeItem(key)
      }
    }
  }

  /**
   * Returns unified state
   * @param {state} state
   * @returns {object} unifiedState
   */
  static getUnifiedState (state) {
    const data = this.cloneDeep(state) // Remove watchers
    data.scenes = data.scenes.map(s => {
      s.modules = s.modules.map(m => {
        m.preview.active = false
        if (m.preview.hasOwnProperty("transformAnimation")) {
          delete m.preview.transformAnimation
        }
        if (m.preview.hasOwnProperty("animationClass")) {
          delete m.preview.animationClass
        }
        const group = this.getModuleDataValue(m, "group", null)
        if (group) {
          group.activeGroupIndex = 0
          this.setModuleDataValue(m, "group", group)
        }
        return m
      })

      return s
    })

    // Skip settings / activeDesign fields - leave only the most important state
    return {
      scenes: data.scenes,
      events: data.events
    }
  }

  /**
   * @param type
   * @returns {string}
   */
  static getFriendlyModuleType (type) {
    switch (type) {
      case ThreeDModuleType:
        return "3D"
      case PanoModuleType:
        return "Panorama"
      case "galleryModule":
      case SwiperGroupModuleType:
        return "Gallery"
      case LightweightSwiperGroupModuleType:
        return "Lightweight Gallery"
      case "global_clickouts":
        return "Global Clickouts"
    }

    return this.ucfirst(type.replace(/module/gi, ""))
  }

  /**
   * Returns special grouping name
   * @param {module} module
   * @param {number} groupIndex
   * @param {module[]} modules
   * @param designFormat
   * @returns {string|null}
   */
  static getSpecialGroupingName (module, groupIndex, modules = [], designFormat) {
    const parentModule = modules.find(m => m.uuid === module.parentModuleId)
    if (parentModule && parentModule.type === OfferistaModuleType) {
      return groupIndex === 0 ? "Master slide" : "Load more slide"
    }
    if (module.type === SwiperGroupModuleType && designFormat === AD_FORMATS.vast_csv_gallery) {
      return "Master slide"
    }

    switch (module.type) {
      case SwiperGroupModuleType:
      case LightweightSwiperGroupModuleType:
        return `Slide #${groupIndex + 1}`
      case AssetGroupModuleType:
        return `Group #${groupIndex + 1}`
      case StoryModuleType:
        return `Scene #${groupIndex + 1}`
      case MatchModuleType:
        const groupsLength = this.getModuleDataValue(module, "group", [])?.groups.length
        return groupsLength - 1 === groupIndex ? "Default end card" : `Card #${groupIndex + 1}`
      case SlidebarModuleType:
        return `Slidebar ${groupIndex === 0 ? "Background" : "Foreground"}`
    }

    return null
  }

  /**
   * @param design
   * @param tag
   * @returns {boolean}
   */
  static hasAnyClickoutSetInTag (design, tag) {
    if (design?.globalClickout?.length > 0) {
      return true
    }

    let result = true
    const clicks = []
    const modules = this.getAllModulesFromVariants(design.variants)
    modules.forEach(m => {
      if (m.clickable) {
        m.data.forEach(d => {
          if (d.type === "clickout") {
            clicks.push({
              id: m.uuid,
              htmlId: m.htmlId,
              name: m.name
            })
          }
        })
      }
    })

    if (clicks.length) {
      result = false
      clicks.forEach(c => {
        if (
          tag.configuration.clickouts[c.htmlId] &&
          tag.configuration.clickouts[c.htmlId].length
        ) {
          result = true
        }
      })
    }

    return result
  }

  /**
   * Makes avatar out of name
   *
   * @param firstName
   * @param lastName
   * @param colorName
   * @param size
   * @param returnHtml
   * @param saturation
   * @param lightness
   * @param figureTag
   * @returns {string}
   */
  static namedAvatar (
    firstName,
    lastName,
    colorName,
    size = 64,
    returnHtml = true,
    saturation = 70,
    lightness = 45,
    figureTag = "circle"
  ) {
    const initials = (
      firstName.substr(0, 1) + lastName.substr(0, 1)
    ).toLocaleUpperCase()
    const background = this.stringToHslColor(colorName, saturation, lightness)
    const color = encodeURIComponent("#ffffff")
    const fontSize = Math.round(size / 2.5)
    const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${size}px" height="${size}px" viewBox="0 0 ${size} ${size}" version="1.1"><${figureTag} fill="${background}" cx="${size /
    2}" width="${size}" height="${size}" cy="${size / 2}" r="${size /
    2}"></${figureTag}><text x="50%" y="50%" style="color: ${color}; line-height: 1;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;" alignment-baseline="middle" text-anchor="middle" font-size="${fontSize}" font-weight="500" dy=".1em" dominant-baseline="middle" fill="${color}">${initials}</text></svg>`

    if (returnHtml) {
      return svgContent
    }

    return "data:image/svg+xml;utf8," + svgContent
  }

  /**
   * Returns string to HSL color
   * @param {string} str
   * @param {number} s - saturation
   * @param {number} l - lightness
   * @returns {string}
   */
  static stringToHslColor (str, s, l) {
    let hash = 0
    for (let i = 0; i < String(str).length; i++) {
      hash = String(str).charCodeAt(i) + ((hash << 5) - hash)
    }

    const h = hash % 360
    return "hsl(" + h + ", " + s + "%, " + l + "%)"
  }

  /**
   * Makes brand fallback image out of name
   *
   * @param name
   * @param returnHtml
   * @param color
   * @returns {string}
   */
  static brandAvatar (name, returnHtml = true, color) {
    if (!color) {
      color = "#d6dae0"
    }

    color = color.replace("#", "%23")

    const svgContent = `%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='44' viewBox='0 0 43 44'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Crect width='44' height='44' fill='${color}'%3E%3C/rect%3E%3Cg fill-rule='nonzero'%3E%3Cpath fill='%23B0B4B9' d='M15.942 2.32C7.135 2.32 0 8.225 0 15.52s7.14 13.198 15.942 13.198c8.8 0 15.942-5.909 15.942-13.198 0-7.29-7.117-13.198-15.942-13.198zm6.325 12.059l-5.831 7.392c-.185.227-.523.327-.836.247-.313-.08-.528-.321-.533-.597v-4.653h-4.552c-.148.001-.292-.036-.414-.106-.344-.194-.438-.587-.21-.878l5.83-7.387c.186-.227.524-.328.836-.248.313.08.529.322.534.598V13.4h4.587c.272.006.518.136.643.34.125.204.109.449-.042.64h-.012z' transform='rotate(-53 25.845 12.824)'/%3E%3Cpath fill='%2341454A' d='M15.942 0C7.135 0 0 5.904 0 13.198c0 7.295 7.14 13.199 15.942 13.199 8.8 0 15.942-5.91 15.942-13.199C31.884 5.908 24.767 0 15.942 0zm6.325 12.058l-5.831 7.392c-.185.228-.523.328-.836.248-.313-.08-.528-.322-.533-.598v-4.652h-4.552c-.148 0-.292-.036-.414-.107-.344-.194-.438-.586-.21-.878l5.83-7.387c.186-.227.524-.327.836-.247.313.08.529.321.534.598v4.652h4.587c.272.005.518.136.643.34.125.203.109.448-.042.639h-.012z' transform='rotate(-53 25.845 12.824)'/%3E%3C/g%3E%3Cellipse cx='27' cy='37' fill='%2341454A' opacity='.036' rx='13' ry='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A`

    if (returnHtml) {
      return svgContent
    }

    return "data:image/svg+xml;utf8," + svgContent
  }

  /**
   * Parses number by locale settings
   * @param number
   * @param locale
   * @param decimals
   * @returns {string}
   */
  static parseNumber (number, locale = "de-DE", decimals = 0) {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: decimals
    }).format(number)
  }

  /**
   * @param time Unix timestamp or a datetime string
   * @param withoutSuffix Disable suffix (... ago)Z
   * @returns {string}
   */
  static timeAgoFormatter (time, withoutSuffix) {
    dayjs.extend(updateLocale)
    dayjs.extend(relativeTime)
    dayjs.extend(utc)

    const localeSpec = {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%d sec.",
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        w: "a week",
        ww: "%d wks",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d yrs."
      }
    }

    if (withoutSuffix === true) {
      localeSpec.relativeTime.s = localeSpec.relativeTime.ss = localeSpec.relativeTime.m = localeSpec.relativeTime.mm = localeSpec.relativeTime.h = localeSpec.relativeTime.hh =
        "today"
    }

    dayjs.updateLocale("en", localeSpec)

    return dayjs.utc(time).fromNow(withoutSuffix)
  }

  /**
   * Returns active scene's modules
   * @param activeSceneUuid
   * @param scenes
   * @returns {*}
   */
  static getActiveScene (activeSceneUuid, scenes) {
    return scenes
      ? scenes.find(s => s.uuid === activeSceneUuid)
      : null
  }

  static filterObject (obj, callback) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, val]) => callback(val, key))
    )
  }

  /**
   * Returns unique values in array
   * @param arr
   * @returns {*}
   */
  static uniqueValues (arr) {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
  }

  /**
   * Parses the params object to extract filters to separate array
   * @param params
   * @returns {string}
   */
  static parseUrlFilter (params) {
    // normalize filters
    const parsedFilters = []
    if (params.filters) {
      const filters = params.filters
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          filters[key].forEach(value => {
            parsedFilters.push("filters[" + key + "][]=" + value)
          })
        }
      }
      delete params.filters
    }

    return (
      new URLSearchParams(params).toString() +
      (parsedFilters ? "&" + parsedFilters.join("&") : "")
    )
  }

  /**
   * Returns active scene's modules
   * @param scenes
   * @returns {*}
   */
  static getAllModules (scenes) {
    return scenes.flatMap(scene => scene.modules)
  }

  /**
   * Returns all modules from variants
   * @param {variant[]} variants
   * @param {scene[]} scenesFallback
   * @returns {module[]}
   */
  static getAllModulesFromVariants (variants, scenesFallback = null) {
    if (variants && variants?.length > 0 && variants[0].scenes) {
      const scenes = [...variants[0].scenes]
      return this.getAllModules(scenes)
    }
    return scenesFallback ? this.getAllModules(scenesFallback) : []
  }

  /**
   * Returns module by uuid
   * @returns {module}
   * @param uuid
   * @param modules
   */
  static getModuleById (uuid, modules) {
    return modules.find(m => m.uuid === uuid)
  }

  /**
   * Returns module by htmlId
   * @returns {*}
   * @param htmlId
   * @param modules
   */
  static getModuleByHtmlId (htmlId, modules) {
    return modules.find(m => m.htmlId === htmlId)
  }

  /**
   * Returns module by htmlId
   * @returns {*}
   * @param uuidPart
   * @param modules
   */
  static getModuleByUuidPart (uuidPart, modules) {
    return modules.find(m => m.uuid.match(new RegExp(`${uuidPart}$`, "i")))
  }

  /**
   * Returns first module found by type
   * @returns {module|undefined}
   * @param type
   * @param modules
   */
  static getModuleByType (type, modules) {
    return modules.find(m => m.type === type)
  }

  /**
   * Returns module by intId
   * @returns {*}
   * @param intId
   * @param modules
   */
  static getModuleByIntId (intId, modules) {
    return modules.find(m => m.intId === intId)
  }

  static setObjectValue (obj, value, path) {
    const a = path.split(".")
    let o = obj
    while (a.length - 1) {
      const n = a.shift()
      if (!(n in o)) o[n] = {}
      o = o[n]
    }
    o[a[0]] = value
  }

  /**
   * Simplified method for fetching url parameters
   * @param url
   * @returns {{}}
   */
  static getUrlParameters (url) {
    const qs = {}
    url
      .replace("?", "")
      .split("&")
      .forEach(row => {
        const splitRow = row.split("=")
        qs[splitRow[0]] = splitRow[1]
      })

    return qs
  }

  /**
   * Returns active scene's modules
   * @param activeSceneUuid
   * @param scenes
   * @returns {*}
   */
  static getActiveSceneModules (activeSceneUuid, scenes) {
    const currentScene = this.getActiveScene(activeSceneUuid, scenes)
    return currentScene ? currentScene.modules : []
  }

  /**
   * Selects content in given node
   * @param element
   */
  static selectContent (element) {
    let range
    if (document.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(element)
      range.select()
    } else if (window.getSelection) {
      const selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(element)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  /**
   * @param string
   * @returns {*}
   */
  static escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
  }

  /**
   * @param text
   * @param width
   * @param height
   * @param font
   * @param textX
   * @param textY
   * @param bg
   * @param color
   * @param format
   * @param quality
   * @returns {string}
   */
  static createTextImage (
    text,
    width = 100,
    height = 100,
    font = "32px Open Sans",
    textX = 40,
    textY = 60,
    bg = "#131313",
    color = "#ffffff",
    format = "image/jpeg",
    quality = 90
  ) {
    const c = document.createElement("canvas")
    c.width = width
    c.height = height
    const ctx = c.getContext("2d")
    ctx.beginPath()
    ctx.rect(0, 0, c.width, c.height)
    ctx.fillStyle = bg
    ctx.fill()

    ctx.font = font
    ctx.fillStyle = color
    ctx.fillText(text, textX, textY)
    return c.toDataURL(format, quality)
  }

  /**
   * @param img
   * @param width
   * @param height
   * @param srcWidth
   * @param srcHeight
   * @param format
   * @param quality
   * @returns {string}
   */
  static createCanvasImage (
    img,
    width = 100,
    height = 100,
    srcWidth = 100,
    srcHeight = 100,
    format = "image/jpeg",
    quality = 90
  ) {
    const c = document.createElement("canvas")
    c.width = width
    c.height = height
    const ctx = c.getContext("2d")

    const ratioOrig = srcWidth / srcHeight

    ctx.canvas.width = width
    ctx.canvas.height = height / ratioOrig

    ctx.drawImage(
      img,
      0,
      0,
      srcWidth,
      srcHeight,
      0,
      0,
      width,
      height / ratioOrig
    )
    return c.toDataURL(format, quality)
  }

  /**
   * @param {Object} unordered
   * @returns {{}}
   */
  static sortObject (unordered) {
    const ordered = {}
    Object.keys(unordered)
      .sort()
      .forEach(function (key) {
        ordered[key] = unordered[key]
      })

    return ordered
  }

  /**
   * Helper method for reduce
   * @param asset
   * @param prevDir
   * @param currDir
   * @param i
   * @param filePath
   * @returns {*}
   */
  static mergePathsIntoFileTree (asset, prevDir, currDir, i, filePath) {
    if (!prevDir.hasOwnProperty("/" + currDir)) {
      prevDir["/" + currDir] = { _files: [], _size: 0 }
    }

    if (i === filePath.length - 1 && asset) {
      prevDir["/" + currDir]._files.push(asset)
    }

    return prevDir["/" + currDir]
  }

  /**
   * Returns design screenshot fallback image
   * @returns {string}
   */
  static fallbackImage () {
    return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Cpath fill='%233b3e44' d='M0 0h128v128H0z'/%3E%3C/svg%3E"
  }

  /**
   * Applies callback function to every level of a tree
   * @param fileTree
   * @param callback
   * @returns {*|{}}
   */
  static applyTreeCallback (fileTree, callback) {
    const levelIterate = (level, callback, path) => {
      for (const key in level) {
        if (level.hasOwnProperty(key) && key !== "_files" && key !== "_size") {
          const newPath = path === "/" ? key : path + key
          levelIterate(level[key], callback, newPath)
        } else {
          callback(path, level)
        }
      }
    }
    levelIterate(fileTree, callback, "")
  }

  /**
   * Applies callback function to every level of a tree
   * @param fileTree
   * @param structureLevel
   * @returns {*|{}}
   */
  static getTreeLevel (fileTree, structureLevel) {
    if (!fileTree || !structureLevel) {
      return {}
    }

    if (structureLevel === "/") {
      return fileTree[structureLevel] || {}
    }

    const structureLevelSplit = structureLevel.trim().split("/")
    fileTree = fileTree || {}
    const levelIterate = (prevDir, currDir) => {
      if (prevDir.hasOwnProperty("/" + currDir)) {
        return prevDir["/" + currDir]
      }
    }
    return structureLevelSplit.reduce(levelIterate, fileTree)
  }

  /**
   * Parses file path into a tree
   * @param filePath
   * @param asset
   * @param fileTree
   * @returns {string}
   */
  static parseFilePath (filePath, asset, fileTree = {}) {
    if (filePath === "/") {
      this.mergePathsIntoFileTree(asset, fileTree, "", 0, [""])
      return fileTree
    }

    const fileLocation = filePath.trim().split("/")
    fileTree = fileTree || {}

    fileLocation.reduce(
      this.mergePathsIntoFileTree.bind(this, asset),
      fileTree
    )
    return fileTree
  }

  /**
   * Returns parsed transforms
   * @param {string} transformString
   * @returns {object} parsedTransforms
   */
  static parseTransform (transformString) {
    const properties = transformString.split(" ") || []
    const currentProperties = {}

    properties.forEach(_property => {
      const valueOpeningPosition = _property.indexOf("(")
      const valueClosingPosition = _property.indexOf(")", valueOpeningPosition)
      const propertyName = _property.slice(0, valueOpeningPosition)

      if (propertyName) {
        currentProperties[propertyName] = _property.slice(
          valueOpeningPosition + 1,
          valueClosingPosition
        )
      }
    })

    return currentProperties
  }

  /**
   * Translates string to int
   * @param {string} translate
   * @returns {int}
   */
  static translateToInt (translate) {
    return parseInt(translate.replace(/[^-\d.]+/, ""))
  }

  /**
   * Translates string to float
   * @param {string} translate
   * @returns {int}
   */
  static translateToFloat (translate) {
    return parseFloat(translate.replace(/[^-\d.]+/, ""))
  }

  /**
   * Validates email
   * @param {string} value
   * @returns {boolean}
   */
  static validateEmail (value) {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase())
  }

  /**
   * Validates password
   * @param {string} value
   * @returns {validationObj}
   */
  static validatePassword (value) {
    const checkLength = value.length >= 8
    const checkLowerCase = /[a-z|ç|ş|ö|ü|ı|ğ]/u.test(value)
    const checkUpperCase = /[A-Z|Ç|Ş|Ö|Ü|İ|Ğ]/u.test(value)
    const checkNumber = /[0-9]/.test(value)
    const checkSpecialCharacter = /[^A-Za-z0-9]/.test(value)
    return {
      lowercase: checkLowerCase,
      uppercase: checkUpperCase,
      number: checkNumber,
      minChar: checkLength,
      specialCharacter: checkSpecialCharacter
    }
  }

  /**
   * Encodes transform to css
   * @param {object} transformObject
   * @returns {string}
   */
  static encodeTransform (transformObject) {
    return Object.entries(transformObject)
      .map(data => {
        return `${data[0]}(${data[1]})`
      })
      .join(" ")
  }

  /**
   * Checks if module has data
   * @param {module} module
   * @param {string} type
   * @returns {boolean}
   */
  static moduleHasData (module, type) {
    return (
      module.data.findIndex(row => {
        return row.type === type
      }) !== -1
    )
  }

  /**
   * Sets module data value
   * @param {module} module
   * @param {string} type
   * @param {string|number|boolean|null|array} newValue
   * @param {store} [store]
   * @returns {module}
   */
  static setModuleDataValue (module, type, newValue, store) {
    return this.setModuleDataProperty(module, type, "value", newValue, store)
  }

  /**
   * Sets module data property
   * @param {module} module
   * @param {string} type
   * @param {string} propertyName
   * @param {string|number|boolean|null|array} newValue
   * @param {store} [store]
   * @returns {module}
   */
  static setModuleDataProperty (
    module,
    type,
    propertyName,
    newValue,
    store
  ) {
    if (store) {
      return store.commit("setModuleDataPropertyWithSceneId", {
        uuid: module.uuid,
        type,
        propertyName,
        newValue
      })
    } else {
      if (!module || !module.data) {
        return module
      }

      module.data = module.data.map(row => {
        if (row.type === type) {
          row[propertyName] = newValue
        }

        return row
      })

      return module
    }
  }

  /**
   * Gets module data index
   * @param {module} module
   * @param {string} type
   * @param {string|number|boolean|null} defaultValue
   * @returns {number}
   */
  static getModuleDataIndex (module, type, defaultValue = null) {
    if (!module || !module.data) {
      return defaultValue
    }

    return module.data.findIndex(row => {
      return row.type === type
    })
  }

  /**
   * Gets module data value
   * @param {module} module
   * @param {string} type
   * @param {string|number|boolean|null|array} defaultValue
   * @returns {string|number|boolean|null|array|defaultValue}
   */
  static getModuleDataValue (module, type, defaultValue = null) {
    if (!module || !module.data) {
      return defaultValue
    }

    const filtered = module.data.filter(row => {
      return row.type === type
    })

    return filtered && filtered.length ? filtered[0].value : defaultValue
  }

  /**
   * Gets module data
   * @param {module} module
   * @param {string} type
   * @returns {data|{}}
   */
  static getModuleData (module, type) {
    if (!module || !module.data) {
      return {}
    }

    const filtered = module.data.filter(row => {
      return row.type === type
    })

    return filtered && filtered.length ? filtered[0] : {}
  }

  /**
   * Checks if design is responsive
   * @param {design} design
   * @returns {boolean}
   */
  static isDesignResponsive (design) {
    return Boolean(design && design.deviceSizing === "responsive")
  }

  /**
   * Gets design dimensions
   * @param {design} design
   * @returns {object} dimensions
   */
  static getDimensions (design) {
    if (design) {
      let activeVariant = design.variants && design.variants.length > 1
        ? design.variants.find(v => v.active === true)
        : undefined

      activeVariant = !activeVariant && design.variants && design.variants.length === 1 ? design.variants[0] : activeVariant

      if (!activeVariant) {
        return {
          width: design.deviceDimensions.pw,
          height: design.deviceDimensions.ph
        }
      }

      return {
        width: activeVariant ? activeVariant.dimensions[0] : 320,
        height: activeVariant ? activeVariant.dimensions[1] : 480
      }
    }

    return {
      width: 320,
      height: 480
    }
  }

  /**
   * Gets scene dimensions
   * @param {design} design
   * @param scenes
   * @param sceneUuid
   * @returns {object} dimensions
   */
  static getSceneDimensions (design, scenes, sceneUuid) {
    const activeSceneIndex = scenes?.findIndex((s) => s.uuid === sceneUuid)
    if (design) {
      if (EXPANDABLE_BANNER_FORMATS.includes(design.format) && activeSceneIndex === 0) {
        const [width, height] = this.getMobileSizings().find(s => s.type === design.format).bannerSizing
        return {
          width,
          height
        }
      }

      let activeVariant = design.variants && design.variants.length > 1
        ? design.variants.find(v => v.active === true)
        : undefined

      activeVariant = !activeVariant && design.variants && design.variants.length === 1 ? design.variants[0] : activeVariant

      if (!activeVariant) {
        return {
          width: design.deviceDimensions.pw,
          height: design.deviceDimensions.ph
        }
      }

      return {
        width: activeVariant ? activeVariant.dimensions[0] : 320,
        height: activeVariant ? activeVariant.dimensions[1] : 480
      }
    }

    return {
      width: 320,
      height: 480
    }
  }

  /**
   * @param {object} activeDesign
   * @param  {object}  module
   * @param {array}  allModules
   * @param scenes
   * @returns {object} module dimensions
   */
  static getModuleDimensions ({ activeDesign, module = {}, allModules, scenes }) {
    if (!module.parentModuleId) {
      return this.getSceneDimensions(activeDesign, scenes, module.sceneId)
    }
    const parentModule = allModules.find(m => m.uuid === module.parentModuleId)

    return {
      width: parentModule.preview.width,
      height: parentModule.preview.height
    }
  }

  /**
   * This util is for manual DOM element (module) style assignment - faster than Vue watching for props
   * @param module module object
   * @param childSelector DOM child selector, leave blank if root element has
   * @param styleProperty style property
   * @param styleValue style property value
   */
  static preRenderStyles (module, childSelector, styleProperty, styleValue) {
    document.querySelector(`#module-${module.uuid} ${childSelector}`).style[
      styleProperty
    ] = styleValue
  }

  static getFriendlyDeviceType (deviceType) {
    switch (deviceType) {
      case "phone":
      case "tablet":
        return "Mobile"
      case "vast":
        return "VAST"
      case "desktop":
      default:
        return "Desktop"
    }
  }

  static getPreviewType (type, format) {
    switch (format) {
      case AD_FORMATS.interscroller:
        return "interscroller"
      case AD_FORMATS.vast_interstitial:
        return "vast"
      case AD_FORMATS.interstitial:
      case AD_FORMATS.interstitial_320x568:
        return "interstitial"
      default:
        return "normal"
    }
  }

  /**
   * Gets friendly design format
   * @param {string} designFormat
   * @param {string} deviceType
   * @param {string} adProduct
   * @returns {string|"Custom format"}
   */
  static getFriendlyDesignFormat (designFormat, deviceType, adProduct) {
    let entry
    if (adProduct === PRODUCT_DOOH) {
      entry = this.getDoohSizings().find(s => s.type === designFormat)
    } else if (adProduct === PRODUCT_LIVING_ADS) {
      entry = this.getLivingAdsFormats().find(s => s.type === designFormat)
    } else if (adProduct === PRODUCT_VAST) {
      entry = this.getVastAdsFormats().find(s => s.type === designFormat)
    } else if (deviceType === "desktop") {
      entry = this.getDesktopSizings().find(s => s.type === designFormat)
    } else {
      entry = this.getMobileSizings().find(s => s.type === designFormat)
    }

    return entry ? entry.title : "Custom"
  }

  /**
   * Gets friendly dimensions
   * @param {design} design
   * @returns {string|""}
   */
  static getFriendlyDimensions (design) {
    const dims = this.getDimensions(design)

    if (design?.format === AD_FORMATS.living_ads_hot_or_not) {
      return "responsive"
    }

    if (dims) {
      return dims.width + "x" + dims.height
    }
  }

  /**
   * Calculates relative value
   * @param {number} value
   * @param {number} valueParent
   * @returns {string}
   */
  static calculateRelativeValue (value, valueParent) {
    const valueRelative = (value / valueParent) * 100
    return valueRelative.toFixed(2)
  }

  /**
   * Calculates absolute value
   * @param {number} value
   * @param {number} valueParent
   * @returns {string}
   */
  static calculateAbsoluteValue (value, valueParent) {
    const valueAbsolute = (value / 100) * valueParent
    return valueAbsolute.toFixed(2)
  }

  /**
   * @param {string} text
   * @returns {string}
   */
  static ucfirst (text) {
    return (
      String(text)
        .substr(0, 1)
        .toLocaleUpperCase() + String(text).substring(1)
    )
  }

  /**
   * Returns poster cache key
   * @param {string} sourceUrl
   * @returns {string}
   */
  static getPosterCacheKey (sourceUrl) {
    return "posterCache_" + sourceUrl
  }

  /**
   * @param value
   * @returns {boolean}
   */
  static validateHttpUrl (value) {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i
    return String(value).length === 0 || regex.test(value)
  }

  /**
   * @param value
   * @returns {boolean}
   */
  static validateTelUri (value) {
    const regex = /^tel:[0-9+]{1,}[0-9-]{3,15}/i
    return String(value).length === 0 || regex.test(value)
  }

  /**
   * Converts size to most friendly unit
   * @param {number} size
   * @param {number} dec Number of decimals (default: 1)
   * @param {string} sep Number of decimals (default: 1)
   */
  static getFriendlySize (size, dec = 1, sep = "") {
    const kb = size / 1024
    const mb = kb / 1024

    if (isNaN(Number(size))) {
      return ""
    }

    if (mb >= 1) {
      return mb.toFixed(dec) + sep + "MB"
    } else if (kb >= 1) {
      return kb.toFixed(dec) + sep + "KB"
    }

    return size.toFixed(dec) + sep + "B"
  }

  /**
   * Returns poster dimensions
   * @param {string} sourceUrl
   * @returns {object}
   */
  static getPosterDimensions (sourceUrl) {
    const cacheKey = this.getPosterCacheKey(sourceUrl)
    return new Promise(resolve => {
      const getDataAndResolve = () => {
        const data = JSON.parse(sessionStorage.getItem(cacheKey))
        resolve({ width: data.width, height: data.height })
      }
      if (sessionStorage.getItem(cacheKey)) {
        getDataAndResolve()
      } else {
        Utils.extractPoster(sourceUrl).then(() => {
          getDataAndResolve()
        })
      }
    })
  }

  /**
   * Extracts poster
   * @param {string} sourceUrl
   * @returns {object}
   */
  static extractPoster (sourceUrl) {
    const cacheKey = this.getPosterCacheKey(sourceUrl)
    if (sourceUrl.match(/\.mp4$/)) {
      return new Promise(resolve => {
        if (sessionStorage.getItem(cacheKey)) {
          const data = JSON.parse(sessionStorage.getItem(cacheKey))
          resolve(data.image)
        } else {
          const video = document.createElement("video")
          video.src = sourceUrl + "#t=2"
          video.muted = true
          video.addEventListener(
            "timeupdate",
            function () {
              if (this.currentTime > 0) {
                const canvas = document.createElement("canvas")
                const w = video.videoWidth
                const h = video.videoHeight
                canvas.width = w
                canvas.height = h

                const ctx = canvas.getContext("2d")
                ctx.drawImage(this, 0, 0, w, h)
                this.pause()

                const base64 = canvas.toDataURL()
                sessionStorage.setItem(
                  cacheKey,
                  JSON.stringify({
                    image: base64,
                    width: w,
                    height: h
                  })
                )
                resolve(base64)
              }
            },
            false
          )

          video.load()
          video.play()
        }
      })
    }

    return new Promise((resolve, reject) => reject(new Error("Invalid url")))
  }

  /**
   * Returns children modules
   * @param {module} module
   * @param {module[]} modules
   * @returns {module[]}
   */
  static getChildrenModules (module, modules) {
    const childrenArray = []

    const getChildrenModules = (m, addModule = false) => {
      if (!m) {
        return
      }

      if (addModule) {
        childrenArray.push(m)
      }

      const value = this.getModuleDataValue(m, "group", null)

      if (value) {
        value.groups.forEach(group => {
          group.forEach(subModUuid => {
            getChildrenModules(modules.find(m => m.uuid === subModUuid), true)
          })
        })
      }
    }

    getChildrenModules(module, false)

    childrenArray.reverse()

    return [
      ...new Set([
        ...childrenArray,
        ...modules.filter(m => m.parentModuleId === module.uuid)
      ])
    ]
  }

  /**
   * Returns modules z-index
   * @param {module[]} modules
   * @returns {object}
   */
  static modulesZIndex (modules) {
    const zIndexObject = {}
    let zIndex = 1
    const getChildrenModules = module => {
      zIndexObject[module.uuid] = zIndex
      zIndex++

      const value = this.getModuleDataValue(module, "group", null)

      if (value) {
        value.groups.forEach(group => {
          group.forEach(subModUuid => {
            const subModule = modules.find(m => m.uuid === subModUuid)
            if (subModule) {
              getChildrenModules(subModule)
            }
          })
        })
      }
    }

    modules
      .filter(m => m.parentModuleId === null)
      .forEach(m => {
        getChildrenModules(m)
      })

    const finalZIndexObject = {}

    Object.entries(zIndexObject).forEach((d) => {
      const values = Object.values(zIndexObject)
      const maxValue = Math.max(...values)
      values.reverse()
      finalZIndexObject[d[0]] = maxValue - d[1] + 1
    })

    return finalZIndexObject
  }

  /**
   * Returns single scene by id
   * @param sceneId
   * @param scenes
   * @returns {*}
   */
  static getSceneById (sceneId, scenes) {
    return scenes.find(scene => scene.uuid === sceneId)
  }

  /**
   * Parse date to DD.MM.YYYY, HH:mm
   * @param date
   * @param time
   * @param format
   * @returns {dayjs.Dayjs}
   */
  static parseDate (date, time = true, format) {
    if (!format) {
      if (time) {
        format = "DD MMM YYYY, HH:mm"
      } else {
        format = "DD MMM YYYY"
      }
    }

    return dayjs(date).format(format)
  }

  /**
   * @param count
   * @param full
   * @returns {string|*}
   */
  static parseImpressionCount (count, full = false) {
    if (count > 1000000) {
      return (count / 1000000).toFixed(1) + (full ? " millions" : "M")
    } else if (count > 1000) {
      return (count / 1000).toFixed(1) + (full ? " thousands" : "K")
    }

    return String(count)
  }

  /**
   * @param campaign
   * @returns {string}
   */
  static getCampaignLiveDate (campaign) {
    let text = ""
    if (campaign.campaign_start && campaign.campaign_end) {
      text = dayjs(campaign.campaign_start).format("D MMM YYYY")
      text += " - " + dayjs(campaign.campaign_end).format("D MMM YYYY")
    }

    return text
  }

  /**
   * Converts hex to rgb
   * @param hex
   * @returns {{r: number, b: number, g: number}|null}
   */
  static hexToRgb (hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: 1
        }
      : null
  }

  /**
   * @param delivered
   * @param targeted
   * @returns {string}
   */
  static getImpressionsPercent (delivered, targeted) {
    return (targeted > 0 ? (delivered / targeted) * 100 : 100).toFixed(0)
  }

  /**
   * @param duration
   * @returns {*}
   */
  static getFriendlyDuration (duration) {
    return duration + (duration === 1 ? " day" : " days")
  }

  /**
   * Checks is user agent is mobile
   * @returns {boolean}
   */
  static isMobile () {
    const userAgent = navigator.userAgent
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(userAgent.substring(0, 4))
  }

  /**
   * Checks if platform is mac
   * @returns {boolean}
   */
  static isMacOS () {
    return navigator.platform.indexOf("Mac") > -1
  }

  /**
   * Parses notification data
   * @param {object} data
   * @returns {string}
   */
  static parseNotification (data) {
    return `<a ${
      data.link ? "href=\"" + data.link + "\"" : ""
    }>${this.getNotificationText(data)}</a>`
  }

  /**
   * Returns notification text
   * @param {object} data
   * @returns {string|Error}
   */
  static getNotificationText (data) {
    switch (data.type) {
      case "postedCommentNotification":
        if (!data.design) {
          throw new InvalidNotificationException()
        }
        const authorName = data.author ? data.author.name : "Anonymous user"
        return `<span class="author">${authorName}</span> commented on <span class="subject">${data.design.name}</span>`
      case "designChangedProgress":
        if (!data.author || !data.design) {
          throw new InvalidNotificationException()
        }
        return `<span class="author">${data.author.name}</span> changed <span class="subject">status</span> of <span class="subject">${data.design.name}</span>
<div class="status-row">
<div class="design-progress design-progress__${data.fromStatus}">${data.fromStatus}</div><span>to</span><div class="design-progress design-progress__${data.toStatus}">${data.toStatus}</div>
</div>
`
      default:
        throw new InvalidNotificationException()
    }
  }

  /**
   * Returns rich tooltip
   * @param {string|null} title
   * @param {string|null} innerContent
   * @param {string|null} footer
   * @param {object|null} bigContent
   * @returns {string}
   */
  static getRichTooltip (title, innerContent, footer, bigContent) {
    let content = ""

    if (title) {
      content += `<span class="tooltip-heading">${title}</span>`
    }

    if (innerContent) {
      content += `<div class="tooltip-innercontent">${innerContent}</div>`
    }

    if (bigContent !== null && bigContent !== undefined) {
      content += `<h4 class="tooltip-value">${bigContent}</h4>`
    }

    if (footer) {
      content += `<h5 class="tooltip-date">${footer}</h5>`
    }

    return `<div class="rich-tooltip">
${content}
</div>`
  }

  /**
   * Parse snake case to capitalized space separated words
   * @param string
   * @returns {string}
   */
  static snakeCaseToCapitalizedWords (string) {
    return string.split("_").map(s => upperFirst(s)).join(" ")
  }

  /**
   * Parse camel case to capitalized space separated words
   * @param string
   * @returns {string}
   */
  static camelCaseToCapitalizedWords (string) {
    const result = string.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  /**
   * Parse camel case to dash-case
   * @param string
   * @returns {string}
   */
  static camelCaseToDashCase (string) {
    return string.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
  }

  /**
   * Parse camel case to kebab-case
   * @param string
   * @returns {string}
   */
  static camelCaseToKebabCase (string) {
    const kebabize = str => {
      return str.split("").map((letter, idx) => {
        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}`
          : letter
      }).join("")
    }

    return kebabize(string)
  }

  /**
   * Returns friendly dooh sizing title
   * @param {string} type
   * @returns {string}
   */
  static getFriendlyDoohSizingTitle (type) {
    switch (type) {
      case AD_FORMATS.infoscreen:
        return "PV Infoscreen "
      case AD_FORMATS.roadside_horizontal:
        return "PV Roadside "
      case AD_FORMATS.roadside_vertical:
        return "PV Roadside "
      case AD_FORMATS.station:
        return "PV Station "
      case AD_FORMATS.mall_vertical:
        return "PV Mall "
      case AD_FORMATS.mall_horizontal:
        return "PV Mall "
      case AD_FORMATS.city_tower:
        return "PV City Tower "
      case AD_FORMATS.city:
        return "PV City "
      default:
        return this.snakeCaseToCapitalizedWords(type)
    }
  }

  /**
   * Returns dooh sizing
   * @returns {array} doohSizings
   */
  static getVastSizings () {
    return [
      {
        title: "Vast intersitial",
        sizing: [1920, 1080],
        type: AD_FORMATS.vast_interstitial
      },
      {
        title: "CSV Gallery",
        sizing: [1920, 1080],
        type: AD_FORMATS.vast_csv_gallery
      }
    ]
  }

  /**
   * Returns VAST formats
   * @returns {array} VAST
   */
  static getVastAdsFormats () {
    return [
      {
        title: "Interstitial",
        sizing: [1920, 1080],
        type: AD_FORMATS.vast_interstitial,
        icon: "wagawin_video_poll",
        id: AD_FORMATS.vast_interstitial,
        disabled: false
      },
      {
        title: "CSV Gallery",
        sizing: [1920, 1080],
        type: AD_FORMATS.vast_csv_gallery,
        icon: "wagawin_video_poll",
        id: AD_FORMATS.vast_csv_gallery,
        disabled: false
      },
      {
        title: "In-Read",
        sizing: [1664, 936],
        type: "vast_inread",
        id: "vast_inread",
        icon: "wagawin_video_poll",
        disabled: true
      },
      {
        title: "In-Banner",
        sizing: [672, 432],
        type: "vast_inbanner",
        id: "vast_inbanner",
        icon: "wagawin_video_poll",
        disabled: true
      },
      {
        title: "Slider",
        sizing: [1080, 1920],
        type: "vast_slider",
        id: "vast_slider",
        icon: "wagawin_video_poll",
        disabled: true
      }
    ]
  }

  /**
   * Returns formatted xml with paddding
   * @returns {string}
   * @param xml
   * @param tab
   */
  static formatXml (xml, tab) { // tab = optional indent value, default is tab (\t)
    let formatted = ""
    let indent = ""
    tab = tab || "\t"
    xml.split(/>\s*</).forEach(function (node) {
      if (node.match(/^\/\w/)) indent = indent.substring(tab.length) // decrease indent by one 'tab'
      formatted += indent + "<" + node + ">\r\n"
      if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab // increase indent
    })
    return formatted.substring(1, formatted.length - 3)
  }

  /**
   * Replaces kebab-case to camelCase
   * @returns {string}
   * @param string
   */
  static kebabCaseToCamelCase (string) {
    return string.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
  }

  /**
   * Replaces snake_case to camelCase
   * @returns {string}
   * @param string
   */
  static snakeCaseToCamelCase (string) {
    return string.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
  }

  /**
   * Returns friendly desktop sizing title
   * @param {string} type
   * @returns {string}
   */
  static getFriendlyDesktopSizingTitle (type) {
    return this.snakeCaseToCapitalizedWords(type).replace(/Desktop /i, "")
  }

  /**
   * Returns friendly living ads format title
   * @param {string} type
   * @returns {string}
   */
  static getFriendlyLivingAdsFormatTitle (type) {
    return this.snakeCaseToCapitalizedWords(type).replace(/Living Ads /i, "")
  }

  /**
   * Returns dooh sizing
   * @returns {array} doohSizings
   */
  static getDoohSizings () {
    return [
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.infoscreen),
        sizing: [1920, 1080],
        type: AD_FORMATS.infoscreen
      },
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.roadside_horizontal),
        sizing: [576, 408],
        type: AD_FORMATS.roadside_horizontal
      },
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.roadside_vertical),
        sizing: [672, 432],
        type: AD_FORMATS.roadside_vertical
      },
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.station),
        sizing: [1080, 1920],
        type: AD_FORMATS.station
      },
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.mall_vertical),
        sizing: [1080, 1920],
        type: AD_FORMATS.mall_vertical
      },
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.mall_horizontal),
        sizing: [1920, 1080],
        type: AD_FORMATS.mall_horizontal
      },
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.city_tower),
        sizing: [336, 1092],
        type: AD_FORMATS.city_tower
      },
      {
        title: this.getFriendlyDoohSizingTitle(AD_FORMATS.city),
        sizing: [1080, 1920],
        type: AD_FORMATS.city
      }
    ]
  }

  /**
   * Returns livingAds formats
   * @returns {array} livingAdsFormats
   */
  static getLivingAdsFormats () {
    return [
      {
        title: this.getFriendlyLivingAdsFormatTitle(AD_FORMATS.living_ads_hot_or_not),
        sizing: [1920, 1080],
        type: AD_FORMATS.living_ads_hot_or_not,
        icon: "wagawin_hot_or_not",
        id: AD_FORMATS.living_ads_hot_or_not,
        disabled: false
      },
      {
        title: this.getFriendlyLivingAdsFormatTitle(AD_FORMATS.living_ads_video_poll),
        sizing: [1664, 936],
        type: AD_FORMATS.living_ads_video_poll,
        id: AD_FORMATS.living_ads_video_poll,
        icon: "wagawin_video_poll",
        disabled: false
      },
      {
        title: this.getFriendlyLivingAdsFormatTitle(AD_FORMATS.living_ads_tree),
        sizing: [672, 432],
        type: AD_FORMATS.living_ads_tree,
        id: AD_FORMATS.living_ads_tree,
        icon: "wagawin_tree",
        disabled: true
      },
      {
        title: this.getFriendlyLivingAdsFormatTitle(AD_FORMATS.living_ads_video_quiz),
        sizing: [1080, 1920],
        type: AD_FORMATS.living_ads_video_quiz,
        id: AD_FORMATS.living_ads_video_quiz,
        icon: "wagawin_video_quiz",
        disabled: true
      },
      {
        title: this.getFriendlyLivingAdsFormatTitle(AD_FORMATS.living_ads_chat),
        sizing: [1080, 1920],
        type: AD_FORMATS.living_ads_chat,
        id: AD_FORMATS.living_ads_chat,
        icon: "wagawin_chat",
        disabled: true
      },
      {
        title: this.getFriendlyLivingAdsFormatTitle(AD_FORMATS.living_ads_emotion),
        sizing: [1920, 1080],
        type: AD_FORMATS.living_ads_emotion,
        id: AD_FORMATS.living_ads_emotion,
        icon: "wagawin_emotion",
        disabled: true
      }
    ]
  }

  /**
   * Returns mobile sizing
   * @returns {array} mobileSizings
   */
  static getMobileSizings () {
    return [
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.interstitial),
        sizing: [320, 480],
        type: AD_FORMATS.interstitial
      },
      {
        title: "Interstitial PL",
        sizing: [320, 568],
        type: AD_FORMATS.interstitial_320x568
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.halfPage),
        sizing: [300, 600],
        type: AD_FORMATS.halfPage
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.interscroller),
        sizing: [320, 480],
        type: AD_FORMATS.interscroller
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.mrec),
        sizing: [300, 250],
        type: AD_FORMATS.mrec
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.expandable_banner_small).replace("Expandable Banner Small", "Exp. Banner S"),
        sizing: [320, 480],
        bannerSizing: [300, 50],
        type: AD_FORMATS.expandable_banner_small
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.expandable_banner_medium).replace("Expandable Banner Medium", "Exp. Banner M"),
        sizing: [320, 480],
        bannerSizing: [320, 50],
        type: AD_FORMATS.expandable_banner_medium
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.expandable_banner_large).replace("Expandable Banner Large", "Exp. Banner L"),
        sizing: [320, 480],
        bannerSizing: [300, 250],
        type: AD_FORMATS.expandable_banner_large
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.leaderboard),
        sizing: [320, 50],
        type: AD_FORMATS.leaderboard
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.fullPage),
        sizing: [320, 320],
        type: AD_FORMATS.fullPage
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.largeBanner),
        sizing: [320, 100],
        type: AD_FORMATS.largeBanner
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.square),
        sizing: [250, 250],
        type: AD_FORMATS.square
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.smallSquare),
        sizing: [200, 200],
        type: AD_FORMATS.smallSquare
      }
    ]
  }

  /**
     * Returns custom sizing
     * @returns {array} customSizings
     */
  static getCustomSizings () {
    return [
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.custom_fixed),
        sizing: [0, 0],
        type: AD_FORMATS.custom_fixed
      },
      {
        title: this.snakeCaseToCapitalizedWords(AD_FORMATS.custom_responsive),
        sizing: [0, 0],
        type: AD_FORMATS.custom_responsive
      }
    ]
  }

  /**
   * Returns mobile sizing name
   * @param {sizing} sizing
   * @returns {string}
   */
  static getMobileSizingName (sizing) {
    return this.getMobileSizings().find(e => isEqual(e.sizing, sizing))
  }

  /**
   * Returns desktop sizing
   * @returns {array} desktopSizings
   */
  static getDesktopSizings () {
    return [
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_medium_banner),
        sizing: [300, 250],
        type: AD_FORMATS.desktop_medium_banner
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_leaderboard),
        sizing: [728, 90],
        type: AD_FORMATS.desktop_leaderboard
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_wide_skyscraper),
        sizing: [160, 600],
        type: AD_FORMATS.desktop_wide_skyscraper
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_halfpage),
        sizing: [300, 600],
        type: AD_FORMATS.desktop_halfpage
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_billboard),
        sizing: [970, 250],
        type: AD_FORMATS.desktop_billboard
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_small_square),
        sizing: [200, 200],
        type: AD_FORMATS.desktop_small_square
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_square),
        sizing: [250, 250],
        type: AD_FORMATS.desktop_square
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_dynamic_sitebar),
        sizing: [300, 600],
        type: AD_FORMATS.desktop_dynamic_sitebar
      },
      {
        title: this.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_dynamic_sitebar),
        sizing: [300, 600],
        type: AD_FORMATS.desktop_dynamic_sidebar
      }
    ]
  }

  /**
   * Checks if route is dynamic
   * @param {string} href
   * @returns {boolean}
   */
  static checkRouteIsDynamic (href) {
    const segments = routes.map(r =>
      r.path.split("/").map(e => (e.startsWith(":") ? null : e))
    )
    const hrefSegments = href
      .split("/")
      .map((e, i, a) =>
        i + 1 === a.length && e.indexOf("?") !== -1
          ? e.slice(0, e.indexOf("?"))
          : e
      )
    let hasDynamicRoute = false

    segments.forEach(segment => {
      if (hasDynamicRoute === false) {
        hasDynamicRoute =
          segment.length === hrefSegments.length &&
          segment.filter((s, k) => {
            return s === null || hrefSegments[k] === s
          }).length === segment.length
      }
    })

    return hasDynamicRoute
  }

  /**
   * Returns module icon name
   * @param {string} moduleType
   * @returns {string}
   */
  static getModuleIconName (moduleType) {
    if ([VideoControlsModuleType].includes(moduleType)) {
      return [kebabCase(VideoModuleType), "18"].join("-")
    }

    if ([OfferistaBannerModuleType, OfferistaAssetModuleType].includes(moduleType)) {
      return [kebabCase(AssetModuleType), "18"].join("-")
    }

    if ([LightweightSwiperGroupModuleType].includes(moduleType)) {
      return [kebabCase(SwiperGroupModuleType), "18"].join("-")
    }

    return [kebabCase(moduleType), "18"].join("-")
  }

  /**
   * Returns trigger icon name
   * @param {string} trigger
   * @returns {string}
   */
  static getTriggerIconName (trigger) {
    if (trigger.startsWith("first_user_interaction")) {
      return "trigger-longpress"
    }
    if (trigger.startsWith("basket") || trigger.startsWith("drop")) {
      return "basket-module-18"
    }
    if (trigger.startsWith("pano_engagement") || trigger.startsWith("pano_ready") || trigger.startsWith("pano_user_interaction")) {
      return "pano-module-18"
    }
    if (trigger.startsWith("video")) {
      return "video-module-18"
    }
    if (trigger.startsWith("popup")) {
      return "popup-module-18"
    }
    if (trigger.startsWith("playPanoVideo")) {
      return "video-pano-module-18"
    }
    if (trigger.startsWith("slidebar")) {
      return "slidebar-module-18"
    }
    if (trigger.startsWith("wipe")) {
      return "wipe-module-18"
    }
    if (trigger.startsWith("gallery")) {
      return "swiper-group-module-18"
    }
    if (trigger.startsWith("shake")) {
      return "shake-module-18"
    }
    if (trigger.startsWith("after:")) {
      return "trigger-after"
    }
    if (trigger.startsWith("nointeraction:")) {
      return "trigger-nointeraction"
    }
    if (trigger.startsWith("sync:")) {
      return "after-specific-event"
    }
    if (trigger.startsWith("calendar")) {
      return "trigger-calendar-mraid-add"
    }
    if (trigger.startsWith("stop_animation")) {
      return "timeline-pause"
    }
    if (trigger.startsWith("survey")) {
      return "survey-module-18"
    }
    if (trigger.includes("ScreenShake")) {
      return "shake-module-18"
    }
    if (trigger.startsWith("image_sequence") || trigger.includes("ImageSequence")) {
      return "image-sequence-module-18"
    }
    if (trigger.startsWith("resetVideo")) {
      return "trigger-play-video"
    }
    if (trigger.startsWith("story_step")) {
      return "trigger-story-on-skip-right"
    }
    if (trigger.startsWith("maps_default")) {
      return "click-2-maps-module-18"
    }
    if (trigger.startsWith("initializeMap")) {
      return "trigger-initialize-map"
    }
    if (trigger.startsWith("initializeMap") || trigger.startsWith("map_activated")) {
      return "trigger-initialize-map"
    }
    if (trigger.startsWith("timer_start") || trigger.startsWith("timer_timestamp") || trigger.startsWith("initializeTimer") || trigger.startsWith("stopTimer") || trigger.startsWith("actionTimerReset") || trigger.startsWith("actionTimerAddTime")) {
      return "trigger-timer"
    }
    if (trigger.startsWith("puzzle_") || trigger.startsWith("complete_puzzle")) {
      return "trigger-puzzle"
    }
    if (trigger.startsWith("memory") || trigger.startsWith("actionCompleteMemory") || trigger.startsWith("no_match") || trigger.startsWith("win") || trigger.startsWith("user_interaction") || trigger.startsWith("reset")) {
      return "memory-module-18"
    }
    if (trigger.startsWith("match")) {
      return "match-module-18"
    }
    if (trigger.startsWith("counter")) {
      return "counter-module-18"
    }
    if (trigger.startsWith("slider")) {
      return "slider-module-18"
    }
    if (trigger.startsWith("share")) {
      return "whatsapp-module-18"
    }
    if (trigger.startsWith("playAnimationOnHover")) {
      return "trigger-play-animation"
    }
    if (trigger.includes("randomSceneChange")) {
      return "trigger-change-scene"
    }

    return "trigger-" + kebabCase(trigger)
  }

  /**
   * Swaps file value
   * @param {scene[]} scenes
   * @param {asset} oldAsset
   * @param {asset} newAsset
   * @returns {scene[]}
   */
  static swapFileValue (scenes, oldAsset, newAsset) {
    const replaceBackgroundTypes = [
      "image",
      "video",
      "customPoster",
      "background"
    ]
    scenes = scenes.map(scene => {
      scene.modules = scene.modules.map(module => {
        module.data = module.data.map(data => {
          if (
            [
              "image",
              "video",
              "background",
              "calendarIcs",
              "data",
              "arrowLeft",
              "arrowRight",
              "particle",
              "brush",
              "customPlayButton",
              "customPoster",
              "eraser",
              "eraserBg",
              "backgroundClean",
              "indicatorImage",
              "indicatorActiveImage"
            ].indexOf(data.type) !== -1 &&
            Number(data.value) === Number(oldAsset.id)
          ) {
            data.value = newAsset.id
            if (replaceBackgroundTypes.includes(data.type)) {
              module.styles["background-image"] =
                "url('" + newAsset.imagepath + "')"
            }
          }
          return data
        })
        return module
      })
      return scene
    })
    return scenes
  }

  /**
   * Returns true if a given array has duplicates
   * @param array
   * @returns {boolean}
   */
  static hasDuplicates (array) {
    return new Set(array).size !== 1
  }

  /**
   * Returns true if given objects are equal
   * @param object1
   * @param object2
   * @returns {boolean}
   */
  static deepEqual (object1, object2) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = this.isObject(val1) && this.isObject(val2)
      if (
        (areObjects && !this.deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false
      }
    }

    return true
  }

  /**
   * Returns true if a given variable is an object
   * @param {object} object
   * @returns {boolean}
   */
  static isObject (object) {
    return object != null && typeof object === "object"
  }

  /**
   * Returns deep clone of an object
   * @param {object} object
   * @returns {object}
   */
  static cloneDeep (object) {
    return JSON.parse(JSON.stringify(object))
  }

  /**
   * Sets transformations on module
   * @param {object} module
   * @param {object} newTransformProperties
   */
  static updateModuleTransformations (module, newTransformProperties) {
    const transformString = module.preview.transform || ""
    const currentProperties = this.parseTransform(transformString)

    const transformCss = Object.entries(
      Object.assign({}, currentProperties, newTransformProperties)
    )
      .map(data => {
        return `${data[0]}(${data[1]})`
      })
      .join(" ")

    module.preview.transform = transformCss
  }

  /**
   * Resizes module and submodules
   * @param {array} modules
   * @param {string} moduleUuid
   * @param {object} oldSize
   * @param {object} newSize
   * @returns {array}
   */
  static resizeModuleAndSubModules (modules, moduleUuid, oldSize, newSize) {
    modules = this.cloneDeep(modules)
    const parentModule = modules.find(m => m.uuid === moduleUuid)
    const changeWidthRatio = oldSize.width / newSize.width
    const changeHeightRatio = oldSize.height / newSize.height

    parentModule.preview = { ...parentModule.preview, ...newSize }

    this.resizeChildModules(modules, moduleUuid, changeWidthRatio, changeHeightRatio)
    return modules
  }

  /**
   * Resizes child modules
   * @param {array} modules
   * @param {string} parentModuleUuid
   * @param {number} changeWidthRatio
   * @param {number} changeHeightRatio
   */
  static resizeChildModules (modules, parentModuleUuid, changeWidthRatio, changeHeightRatio) {
    modules.forEach(cm => {
      if (cm.parentModuleId === parentModuleUuid && !cm.preview.locked && cm.preview.draggable) {
        this.resizeChildModule(modules, cm, changeWidthRatio, changeHeightRatio)
        this.resizeChildModules(modules, cm.uuid, changeWidthRatio, changeHeightRatio)
      }
    })
  }

  /**
   * Resizes child module
   * @param {array} modules
   * @param {object} mc
   * @param {number} changeWidthRatio
   * @param {number} changeHeightRatio
   * @returns {array}
   */
  static resizeChildModule (modules, mc, changeWidthRatio, changeHeightRatio) {
    const parentModule = modules.find(m => m.uuid === mc.parentModuleId)
    // get child module transformations
    const childModuleTransformations = this.parseTransform(mc.preview.transform || "") || {}

    // get X & Y
    const childModuleOriginalX = this.translateToInt(childModuleTransformations.translateX)
    const childModuleOriginalY = this.translateToInt(childModuleTransformations.translateY)

    // get new X & Y transformations
    const newChildModuleX = childModuleOriginalX / changeWidthRatio
    let newChildModuleY = childModuleOriginalY / changeHeightRatio
    if ([VideoModuleType].includes(parentModule.type) && Number(this.getModuleDataValue(parentModule, "automaticHeight", 0)) === 1) {
      const oldHeight = parentModule.preview.height * changeWidthRatio
      const heightToRatio = oldHeight / childModuleOriginalY
      newChildModuleY = parentModule.preview.height / heightToRatio
    }
    const newChildModuleTransformations = Object.assign(
      {},
      childModuleTransformations,
      {
        translateX: Math.round(newChildModuleX) + "px",
        translateY: Math.round(newChildModuleY) + "px"
      }
    )

    const newProperties = {
      width: mc.preview.width / changeWidthRatio,
      height: mc.preview.height / changeHeightRatio,
      transform: this.encodeTransform(Object.assign({}, childModuleTransformations, newChildModuleTransformations))
    }

    if (([VideoModuleType, WipeAdModuleType, VideoStoryModuleType].includes(mc.type) && this.getModuleDataValue(mc, "automaticHeight", 0))) {
      const ratio = mc.preview.height / mc.preview.width
      newProperties.height = newProperties.width * ratio
    }

    if (mc.type === GestureModuleType) {
      newProperties.height = newProperties.width
    }

    if ([VideoModuleType, VideoStoryModuleType].includes(parentModule.type)) {
      const parentHeightToWidthRatio = parentModule.preview.height / parentModule.preview.width
      const oldHeight = parentModule.preview.height / parentHeightToWidthRatio * changeWidthRatio * parentHeightToWidthRatio
      const newHeight = parentModule.preview.height
      const oldHeightToNewHeightRatio = oldHeight / newHeight
      newProperties.height = mc.preview.height / oldHeightToNewHeightRatio
    }

    if (mc.type === VideoControlsModuleType) {
      newProperties.height = this.clamp(MIN_VIDEO_CONTROLS_HEIGHT, newProperties.height, MAX_VIDEO_CONTROLS_HEIGHT)

      if (newChildModuleY + MIN_VIDEO_CONTROLS_HEIGHT > parentModule.preview.height) {
        newChildModuleTransformations.translateY = parentModule.preview.height - MIN_VIDEO_CONTROLS_HEIGHT + "px"
        newProperties.transform = this.encodeTransform(newChildModuleTransformations)
      }
    }

    mc.preview = {
      ...mc.preview,
      ...newProperties
    }
  }

  /**
   * Sorts passed modules first by order in scenes, then by their zIndex
   * @param {scene[]} scenes
   * @param {module[]} modules
   * @returns {module[]}
   */
  static sortModulesBySceneAndIndex (scenes, modules) {
    const sceneOrder = {}
    scenes.forEach((scene, idx) => {
      sceneOrder[scene.uuid] = idx
    })

    return modules.sort((a, b) => {
      if (sceneOrder[a.sceneId] > sceneOrder[b.sceneId]) {
        return 1
      } else if (sceneOrder[a.sceneId] < sceneOrder[b.sceneId]) {
        return -1
      }

      if (a.preview.zIndex > b.preview.zIndex) {
        return -1
      } else if (a.preview.zIndex < b.preview.zIndex) {
        return 1
      }

      return 0
    })
  }

  /**
   * Returns css font weight
   * @param {string|number} fontWeight
   * @returns {string}
   */
  static getCssFontWeight (fontWeight) {
    if (parseInt(fontWeight)) return parseInt(fontWeight)

    fontWeight = fontWeight.toLowerCase()
    const idx = fontWeight.indexOf("italic")
    if (idx > -1) fontWeight = fontWeight.slice(idx)

    switch (fontWeight) {
      case "thin":
      case "hairline":
      case "ultra-light":
      case "extra-light":
        return 100
      case "light":
        return 200
      case "book":
        return 200
      case "regular":
      case "normal":
      case "plain":
      case "roman":
      case "standard":
        return 400
      case "medium":
        return 500
      case "semi-bold":
      case "demi-bold":
        return 600
      case "bold":
        return 700
      case "heavy":
      case "black":
      case "extra-bold":
        return 800
      case "ultra-black":
      case "extra-black":
      case "ultra-bold":
      case "heavy-black":
      case "fat":
      case "poster":
        return 900
      default:
        return 400
    }
  }

  /**
   * Setups timeline object for copy
   * @param {timelineBlock} timelineBlock
   * @returns {timelineBlock}
   */
  static setupTimelineBlockForCopy (timelineBlock) {
    const timelineBlockCopy = this.cloneDeep(timelineBlock)
    delete timelineBlockCopy.uuid
    delete timelineBlockCopy.createdAt
    delete timelineBlockCopy.updatedAt

    return timelineBlockCopy
  }

  /**
   * Setups event animation object for copy
   * @param {event} event
   * @returns {event}
   */
  static setupEventAnimationForCopy (event) {
    event = Utils.cloneDeep(event)
    delete event.uuid

    const delay = event.trigger.split(":")[2] || 0
    event.trigger = `with:${event.uuid}:${delay}`

    return event
  }

  /**
   * Returns converted font size
   * @returns {number} convertedFontSize
   * @param {number} fontSize
   * @param {string} origin
   * @param {string} destination
   * @param {number} viewportWidth
   */
  static convertFontSize ({ fontSize = 14, origin = "px", destination = "vw", viewportWidth = 320 }) {
    switch (destination) {
      case "vw":
        if (origin === "px") return fontSize / viewportWidth * 100
        return fontSize
      case "px":
        if (origin === "vw") return fontSize / 100 * viewportWidth
        return fontSize
      default:
        return fontSize
    }
  }

  /**
   * Truncate given string
   * @param {string} string
   * @param length
   * @param addDots
   */
  static truncate ({ string = "", length = 10, addDots = false }) {
    const truncated = string.substr(0, length)
    if (addDots) {
      return truncated + (string.length > length ? "..." : "")
    }
    return truncated
  }

  /**
   * @returns {array}
   * @param {plugin[]} plugins
   */
  static buildAdtagPluginsStringArray (plugins) {
    const enabledPlugins = plugins.filter(p => p.enabled)

    return enabledPlugins.map(p => {
      let obj = {
        name: p.name
      }

      switch (p.name) {
        case "interstitial":
          obj = {
            ...obj,
            preventScroll: p.options.preventScroll,
            width: p.options.width,
            height: p.options.height
          }
          break
        case "expandableBanner":
          obj = {
            ...obj,
            bannerWidth: p.options.bannerWidth,
            bannerHeight: p.options.bannerHeight,
            expandedWidth: p.options.expandedWidth,
            expandedHeight: p.options.expandedHeight,
            inline: p.options.inline
          }
          break
        case "interscroller":
          const viewabilityShowThreshold = p.options.viewabilityShowThreshold
          obj = {
            ...obj,
            showBars: p.options.showBars,
            fullHeightMode: p.options.fullHeightMode,
            width: p.options.width,
            height: p.options.height
          }
          if (!isNaN(viewabilityShowThreshold)) {
            obj.viewabilityShowThreshold = viewabilityShowThreshold
          }
          break
        case "closeButton":
          obj = {
            ...obj,
            width: p.size,
            height: p.size,
            deadArea: p.deadArea,
            ...(p.deadArea && { deadAreaHeight: p.deadAreaSize, deadAreaWidth: p.deadAreaSize }),
            position: p.position
          }
          break
        case "autoclose":
          obj = {
            ...obj,
            duration: p.duration,
            cancelOnInteraction: p.cancelOnInteraction
          }
          break
      }

      return JSON.stringify(obj)
    })
  }

  /**
   * Returns translate string from transform-origin value
   * @returns {string} translateString
   * @param {string} transformOrigin
   * @param {number} moduleHeight
   * @param {number} moduleWidth
   */
  static transformOriginToTranslate ({ transformOrigin, moduleHeight, moduleWidth }) {
    if (!transformOrigin || transformOrigin === "center center") {
      return `translateX(${moduleWidth / 2 - 10}px) translateY(${moduleHeight / 2 - 10}px)`
    }

    const [translateX, translateY] = transformOrigin.split(" ")
    const translateXToPixels = parseFloat(translateX) / 100 * moduleWidth
    const translateYToPixels = parseFloat(translateY) / 100 * moduleHeight

    return `translateX(${translateXToPixels}px) translateY(${translateYToPixels}px)`
  }

  /**
   * Returns array of objects without duplicate
   * @returns {array<Object>} array without duplicates
   * @param {array<Object>} array
   * @param {string} key
   */
  static removeDuplicatesByKey (array, key) {
    const seen = {}
    return array.filter((item) => seen.hasOwnProperty(item[key]) ? false : (seen[item[key]] = true))
  }

  /**
   * Returns true if file is dragged from outside of window
   * @returns {boolean}
   * @param {object} ev
   */
  static isFileDraggedFromOutside (ev) {
    const types = ev.dataTransfer.types
    if (types.includes("Files") && types.includes("application/x-moz-file")) return true
    return types.includes("Files") && types.length === 1
  }

  /**
   * Returns typo dimensions
   * @param {string} text
   * @param {object} stylesObject
   * @returns {number}
   */
  static getTypoDimensions (text, stylesObject) {
    const el = document.createElement("span")

    const styles = Object.assign({
      backgroundColor: "transparent",
      color: "#FFF",
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      fontFamily: "sans-serif",
      letterSpacing: 0,
      lineHeight: 1.2,
      textDecoration: "none",
      textAlign: "center",
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
      hyphens: "auto",
      cursor: "grab",
      wordBreak: "break-word",
      width: "160px",
      position: "absolute",
      visibility: "hidden"
    }, stylesObject)

    Object.entries(styles).forEach((v) => {
      el.style[v[0]] = v[1]
    })

    el.innerText = text
    document.body.appendChild(el)
    const offsetHeight = el.offsetHeight
    document.body.removeChild(el)

    return offsetHeight
  }

  /**
   * Clamps a value between an upper and lower bound
   * @returns {Number}
   * @param {Number} min
   * @param {Number} val
   * @param {Number} max
   */
  static clamp (min, val, max) {
    return Math.max(min, Math.min(val, max))
  }

  /**
   * Converts preview animation properties to css styles
   * @returns {object} styles
   * @param {object} properties
   */
  static convertPreviewAnimationPropertiesToCSSStyles (properties) {
    const styles = {}

    if (typeof properties.transformAnimation !== "undefined") {
      styles.transform = properties.transformAnimation
    }

    if (typeof properties.opacity !== "undefined") {
      styles.opacity = properties.opacity
    }

    if (typeof properties.borderRadiusAnimation !== "undefined") {
      styles["border-radius"] = properties.borderRadiusAnimation
    }

    if (typeof properties.borderRadius !== "undefined") {
      const { width, height, borderRadius } = properties
      const value = width < height ? width : height

      styles["border-radius"] = value * (borderRadius / 100) + "px"
    }

    if (typeof properties.opacityAnimation !== "undefined") {
      styles.opacity = properties.opacityAnimation
    }

    if (typeof properties.widthAnimation !== "undefined") {
      styles.width = properties.widthAnimation + "%"
    }

    if (typeof properties.heightAnimation !== "undefined") {
      styles.height = properties.heightAnimation + "%"
    }

    if (typeof properties.blurAnimation !== "undefined") {
      styles.filter = `blur(${properties.blurAnimation})`
    }

    if (typeof properties.transformOrigin !== "undefined") {
      styles["transform-origin"] = properties.transformOrigin
    }

    if (typeof properties.animationTimingFunction !== "undefined") {
      styles.animationTimingFunction = properties.animationTimingFunction
    }

    if (typeof properties["clip-path"] !== "undefined") {
      styles["clip-path"] = properties["clip-path"]
    }

    // Adjust transform for flipX, flipY
    const transform = Utils.parseTransform(styles.transform || "")
    if (properties.hasOwnProperty("flipX") && properties.flipX !== false) {
      transform.scaleX = (transform.scaleX || 1) * (properties.flipX === true ? -1 : properties.flipX)
    }
    if (properties.hasOwnProperty("flipY") && properties.flipY !== false) {
      transform.scaleY = (transform.scaleY || 1) * (properties.flipY === true ? -1 : properties.flipY)
    }
    styles.transform = Utils.encodeTransform(transform)

    return styles
  }

  /**
   * Checks if module is excluded in current scope
   * @returns {Boolean} isExcluded
   * @param {String} moduleType
   * @param {String} currentScope
   * @param {Array} sceneModules
   * @param {Array} allModules
   * @param {Object} activeDesign
   * @param {string} activeScene
   */
  static isExcludedModule ({ moduleType, currentScope, sceneModules, allModules, activeDesign, activeScene }) {
    const excludedModules = [VideoControlsModuleType, PollSliderModuleType, SurveySliderModuleType, OfferistaAssetModuleType, OfferistaBannerModuleType, HotOrNotModuleType, WagawinVideoPollModuleType, VastVideoModuleType]

    // Is module excluded on dooh
    const doohExcluded = [PopupModuleType, CountdownModuleType, DestinationModuleType, SlidebarModuleType, WhatsappModuleType, CalendarModuleType, Click2MapsModuleType, SurveyModuleType, PollModuleType, WipeAdModuleType, ParticleWipeAdModuleType, GoogleMapsModuleType, MatchModuleType]
    if (activeDesign?.ad_product === PRODUCT_DOOH && doohExcluded.includes(moduleType)) return true

    const csvVastExcluded = [PopupModuleType, CountdownModuleType, DestinationModuleType, WhatsappModuleType, CalendarModuleType, SlidebarModuleType, Click2MapsModuleType, SurveyModuleType, PollModuleType, WipeAdModuleType, ParticleWipeAdModuleType, GoogleMapsModuleType, MatchModuleType]
    if (activeDesign?.format === AD_FORMATS.vast_csv_gallery && csvVastExcluded.includes(moduleType)) return true

    // Is module excluded in the desktop
    const desktopExcluded = [PanoModuleType, ShakeModuleType]
    if (activeDesign?.deviceType === "desktop" && desktopExcluded.includes(moduleType) && ![AD_FORMATS.custom_fixed, AD_FORMATS.custom_responsive].includes(activeDesign?.format)) return true

    // Is module available only once per ad
    if (AVAILABLE_ONLY_ONCE_PER_CREATIVE.includes(moduleType) && allModules.some(m => m.type === moduleType)) return true

    // Is module available only once per scene
    if (AVAILABLE_ONLY_ONCE_PER_SCENE.includes(moduleType) && sceneModules.some(m => m.type === moduleType)) return true

    if (activeDesign?.format === AD_FORMATS.vast_csv_gallery && currentScope === null && ![BackgroundModuleType, AssetModuleType, SwiperGroupModuleType].includes(moduleType)) return true

    // Is module available only in the root scope
    if (currentScope !== null) {
      if (AVAILABLE_ONLY_IN_ROOT_SCOPE_MODULES.includes(moduleType)) return true
    }

    // Is only one of its module type allowed per scene
    if (NON_DUPLICABLE_MODULES.includes(moduleType) && sceneModules.some(m => m.type === moduleType)) return true

    // Is module available in the current scope
    const scopeModule = allModules.find(m => m.uuid === currentScope)

    const activeSceneIndex = activeDesign?.scenes?.findIndex(s => s.uuid === activeScene)
    if (moduleType === CounterModuleType && activeSceneIndex !== 0) return true

    if (scopeModule) {
      switch (scopeModule.type) {
        case CounterModuleType:
          if (![TypoModuleType].includes(moduleType)) return true
          break
        case MatchModuleType:
          if (![BackgroundModuleType, TypoModuleType, AssetModuleType].includes(moduleType)) return true
          break
        case AssetGroupModuleType:
          if (![BackgroundModuleType, TypoModuleType, AssetModuleType, GestureModuleType, SwiperGroupModuleType, LightweightSwiperGroupModuleType, SlidebarModuleType, WipeAdModuleType, ParticleWipeAdModuleType, CountdownModuleType, StoryModuleType, PollModuleType, SurveyModuleType, WhatsappModuleType, CalendarModuleType, PopupModuleType, EffectType, GoogleMapsModuleType, Click2MapsModuleType, DestinationModuleType, MemoryModuleType, BasketModuleType, TimerModuleType, PuzzleModuleType, SliderModuleType].includes(moduleType)) return true
          break
        case PopupModuleType:
          if (![BackgroundModuleType, AssetModuleType, AssetGroupModuleType, TypoModuleType, SwiperGroupModuleType, VideoModuleType].includes(moduleType)) return true
          break
        case SwiperGroupModuleType:
        case LightweightSwiperGroupModuleType:
          if (![BackgroundModuleType, TypoModuleType, AssetModuleType, AssetGroupModuleType, VideoModuleType, CalendarModuleType, DestinationModuleType, WhatsappModuleType, Click2MapsModuleType, CountdownModuleType, PopupModuleType, EffectType, GoogleMapsModuleType].includes(moduleType)) return true
          break
        case PanoModuleType:
          if (![BackgroundModuleType, TypoModuleType, AssetModuleType, AssetGroupModuleType, VideoModuleType, DestinationModuleType, PopupModuleType, CalendarModuleType, WhatsappModuleType, Click2MapsModuleType].includes(moduleType)) return true
          break
        case SlidebarModuleType:
          if (![BackgroundModuleType, TypoModuleType, AssetModuleType, AssetGroupModuleType, VideoModuleType, DestinationModuleType, PopupModuleType, CalendarModuleType, WhatsappModuleType, Click2MapsModuleType].includes(moduleType)) return true
          break
        case StoryModuleType:
          if (![BackgroundModuleType, AssetModuleType, AssetGroupModuleType, VideoModuleType, TypoModuleType, Click2MapsModuleType].includes(moduleType)) return true
          break
        case DestinationModuleType:
          if (![BackgroundModuleType, TypoModuleType, AssetModuleType, AssetGroupModuleType, VideoModuleType, CalendarModuleType, PopupModuleType, WhatsappModuleType, Click2MapsModuleType, SwiperGroupModuleType].includes(moduleType)) return true
          break
        case TimerModuleType:
          // Allow only one module in the timer module scope
          const childModules = allModules.filter(m => m.parentModuleId === currentScope)
          if (childModules.length > 0) {
            return true
          }

          if (![TypoModuleType].includes(moduleType)) return true
          break
        case OfferistaModuleType:
        case VideoModuleType:
        case VideoStoryModuleType:
        case WipeAdModuleType:
        case SurveyModuleType:
        case PollModuleType:
          return true
      }
    }

    return excludedModules.includes(moduleType)
  }

  /**
   * Returns poll results typo modules
   * @returns {Array} Poll Results Typo Modules
   * @param {Object} parentModule
   */
  static getPollResultsTypoModules ({ parentModule }) {
    const typoLeft = {
      moduleName: "Typo Left",
      parentModuleId: parentModule.uuid,
      type: TypoModuleType,
      skipSelection: true,
      skipHistory: true,
      uuid: uuidv4(),
      data: [
        {
          name: "Poll Result Left",
          type: "typoText",
          value: "%%resultleft%%",
          uuid: uuidv4()
        }
      ],
      preview: {
        active: false,
        width: 130,
        height: 30,
        percentHeight: Utils.calculateRelativeValue(
          30,
          parentModule.preview.height
        ),
        percentWidth: Utils.calculateRelativeValue(
          130,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: "0px",
          translateY: String(parentModule.preview.height - 90 + "px")
        })
      }
    }
    const typoRight = {
      moduleName: "Typo Right",
      parentModuleId: parentModule.uuid,
      type: TypoModuleType,
      skipSelection: true,
      uuid: uuidv4(),
      skipHistory: true,
      data: [
        {
          name: "Poll result right",
          type: "typoText",
          value: "%%resultright%%",
          uuid: uuidv4()
        }
      ],
      preview: {
        active: false,
        width: 130,
        height: 30,
        percentHeight: Utils.calculateRelativeValue(
          30,
          parentModule.preview.height
        ),
        percentWidth: Utils.calculateRelativeValue(
          130,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: String(parentModule.preview.width - 130 + "px"),
          translateY: String(parentModule.preview.height - 90 + "px")
        })
      }
    }

    return [typoLeft, typoRight]
  }

  /**
   * Returns offerista modules
   * @returns {Array} Poll Results Typo Modules
   * @param {Object} parentModule
   */
  static getOfferistaModules ({ parentModule }) {
    const swiperGroupModule = {
      moduleName: "Gallery",
      parentModuleId: parentModule.uuid,
      type: SwiperGroupModuleType,
      skipSelection: true,
      skipHistory: true,
      uuid: uuidv4(),
      preview: {
        active: false,
        width: parentModule.preview.width,
        height: parentModule.preview.height,
        percentHeight: 100,
        percentWidth: 100,
        transform: Utils.encodeTransform({
          translateX: "0px",
          translateY: "0px"
        })
      }
    }

    const typoProductName = {
      moduleName: "Product name",
      parentModuleId: swiperGroupModule.uuid,
      type: TypoModuleType,
      skipSelection: true,
      skipHistory: true,
      uuid: uuidv4(),
      data: [
        {
          name: "Text",
          type: "typoText",
          value: "%%title%%",
          uuid: uuidv4()
        }
      ],
      preview: {
        active: false,
        width: 200,
        height: 30,
        percentHeight: Utils.calculateRelativeValue(
          30,
          parentModule.preview.height
        ),
        percentWidth: Utils.calculateRelativeValue(
          200,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: (parentModule.preview.width - 200) / 2 + "px",
          translateY: String(45 + "px")
        })
      }
    }

    const typoProductDescription = {
      moduleName: "Product description",
      parentModuleId: swiperGroupModule.uuid,
      type: TypoModuleType,
      skipSelection: true,
      uuid: uuidv4(),
      skipHistory: true,
      data: [
        {
          name: "Text",
          type: "typoText",
          value: "%%description%%",
          uuid: uuidv4()
        }
      ],
      preview: {
        active: false,
        width: 200,
        height: 30,
        percentHeight: Utils.calculateRelativeValue(
          30,
          parentModule.preview.height
        ),
        percentWidth: Utils.calculateRelativeValue(
          200,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: (parentModule.preview.width - 200) / 2 + "px",
          translateY: String(parentModule.preview.height / 2 + 50 + "px")
        })
      }
    }

    const assetProductImage = {
      moduleName: "Product image",
      parentModuleId: swiperGroupModule.uuid,
      type: OfferistaAssetModuleType,
      skipSelection: true,
      uuid: uuidv4(),
      skipHistory: true,
      data: [],
      preview: {
        active: false,
        width: 200,
        height: 200,
        percentHeight: Utils.calculateRelativeValue(
          200,
          parentModule.preview.height
        ),
        percentWidth: Utils.calculateRelativeValue(
          200,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: (parentModule.preview.width - 200) / 2 + "px",
          translateY: String(parentModule.preview.height / 4 - 50 + "px")
        })
      }
    }

    const typoProductPrice = {
      moduleName: "Product price",
      parentModuleId: swiperGroupModule.uuid,
      type: TypoModuleType,
      skipSelection: true,
      uuid: uuidv4(),
      skipHistory: true,
      data: [
        {
          name: "Text",
          type: "typoText",
          value: "%%price%%",
          uuid: uuidv4()
        }
      ],
      preview: {
        active: false,
        width: 200,
        height: 30,
        percentHeight: Utils.calculateRelativeValue(
          30,
          parentModule.preview.height
        ),
        percentWidth: Utils.calculateRelativeValue(
          200,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: (parentModule.preview.width - 200) / 2 + "px",
          translateY: String(parentModule.preview.height - 90 + "px")
        })
      }
    }

    const typoClickout = {
      moduleName: "Product clickout",
      parentModuleId: swiperGroupModule.uuid,
      type: TypoModuleType,
      skipSelection: true,
      uuid: uuidv4(),
      skipHistory: true,
      clickable: 1,
      data: [
        {
          name: "Text",
          type: "typoText",
          value: "",
          uuid: uuidv4()
        },
        {
          name: "Clickout",
          type: "clickout",
          value: "%%url%%",
          uuid: uuidv4()
        }
      ],
      preview: {
        active: false,
        width: 200,
        height: 30,
        percentHeight: Utils.calculateRelativeValue(
          30,
          parentModule.preview.height
        ),
        percentWidth: Utils.calculateRelativeValue(
          200,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: (parentModule.preview.width - 200) / 2 + "px",
          translateY: String(parentModule.preview.height - 40 + "px")
        })
      }
    }

    return [swiperGroupModule, typoProductName, typoProductDescription, assetProductImage, typoProductPrice, typoClickout]
  }

  /**
   * Returns offerista flyer modules
   * @returns {Array} Offerista Flyer Modules
   * @param {Object} parentModule
   * @param width
   * @param height
   */
  static getOfferistaFlyerModules ({ parentModule, width, height, url }) {
    const swiperGroupModule = {
      moduleName: "Gallery",
      parentModuleId: parentModule.uuid,
      type: SwiperGroupModuleType,
      skipSelection: true,
      skipHistory: true,
      uuid: uuidv4(),
      preview: {
        active: false,
        width: parentModule.preview.width,
        height: parentModule.preview.height,
        percentHeight: 100,
        percentWidth: 100,
        transform: Utils.encodeTransform({
          translateX: "0px",
          translateY: "0px"
        })
      }
    }

    const offeristaFlyerAssetModule = {
      moduleName: "Flyer",
      parentModuleId: swiperGroupModule.uuid,
      type: OfferistaFlyerAssetModuleType,
      skipSelection: true,
      uuid: uuidv4(),
      skipHistory: true,
      data: [],
      styles: {
        "background-image": `url(${ url })`
      },
      preview: {
        active: false,
        height,
        percentHeight: Utils.calculateRelativeValue(
          height,
          parentModule.preview.height
        ),
        width,
        percentWidth: Utils.calculateRelativeValue(
          width,
          parentModule.preview.width
        ),
        transform: Utils.encodeTransform({
          translateX: "0px",
          translateY: "0px"
        })
      }
    }

    return [swiperGroupModule, offeristaFlyerAssetModule]
  }

  /**
   * Returns offeristaModule from modules tree
   * @param module
   * @param modules
   * @returns {offeristaModule}
   */
  static getRootOfferistaModule ({ module, modules }) {
    const offeristaSwiperGroupModule = modules.find(m => m.uuid === module.parentModuleId && m.type === SwiperGroupModuleType)

    if (!offeristaSwiperGroupModule) {
      return null
    }

    return modules.find(m => m.uuid === offeristaSwiperGroupModule.parentModuleId && m.type === OfferistaModuleType)
  }

  /**
   * Returns closestDataBindingId
   * @param module
   * @param modules
   * @returns closestDataBindingId
   */
  static getClosestDataBindingSourceId ({ module, modules }) {
    const findClosestDataBindingSource = (module) => {
      const parentModule = modules.find(m => m.uuid === module.parentModuleId)

      if (!parentModule) {
        return null
      }

      if ([DestinationModuleType, OfferistaModuleType].includes(parentModule.type)) {
        return parentModule
      }

      return findClosestDataBindingSource(parentModule)
    }

    const closestDataBindingSource = findClosestDataBindingSource(module)
    if (closestDataBindingSource) {
      return Utils.getModuleDataValue(closestDataBindingSource, "dataBindingId", null)
    }

    return null
  }

  /**
   * Returns init data for a given module type
   * @returns {results[]}
   * @param moduleType
   * @param productType
   * @param deviceType
   */
  static getModuleInitData (moduleType, productType = "rich_media", deviceType) {
    let fields = ModuleDataFields
    if (productType === PRODUCT_DOOH) {
      fields = ModuleDataFieldsDooh
    } else if (deviceType === DEVICE_TYPE_DESKTOP) {
      fields = ModuleDataFieldsDesktop
    } else if (productType === PRODUCT_VAST) {
      fields = ModuleDataFieldsVast
    }

    // Remove object reference
    const result = Utils.cloneDeep(fields[moduleType] || [])

    return result.map((res) => {
      res.uuid = uuidv4()
      return res
    })
  }

  /**
   * Returns sinitized unicode
   * @param {string} name
   * @returns {string}
   */
  static sanitizeUnicode (name) {
    return String(name).normalize("NFKD").replace(/[\u0300-\u036f\W]/g, "")
  }

  /**
   * Downloads file from response
   * @param {response} response
   * @param {string} filename
   */
  static downloadFileFromResponse (response, filename = "") {
    let disposition = null
    for (const key in response.headers) {
      if (key.toLowerCase() === "content-disposition") {
        disposition = response.headers[key]
        break
      }
    }
    const parsedDisposition = disposition ? disposition.match(/.*?; filename="?([^"]*)"?/)[1] : ""

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    a.href = url
    a.download = filename || (parsedDisposition || "file.txt")
    a.click()
    window.URL.revokeObjectURL(url)
  }

  static getCountdownResultsTypoModules ({ parentModule }) {
    const typos = []
    const w = 60
    const h = 30
    let offset = (parentModule.preview.width - (4 * w)) / 2;
    ["days", "hours", "minutes", "seconds"].forEach((time) => {
      const typo = {
        moduleName: Utils.ucfirst(time),
        parentModuleId: parentModule.uuid,
        type: TypoModuleType,
        skipSelection: true,
        skipHistory: true,
        uuid: uuidv4(),
        data: [
          {
            type: "typoText",
            value: "0",
            uuid: uuidv4()
          }
        ],
        preview: {
          active: false,
          width: w,
          height: h,
          percentHeight: Utils.calculateRelativeValue(
            h,
            parentModule.preview.height
          ),
          percentWidth: Utils.calculateRelativeValue(
            w,
            parentModule.preview.width
          ),
          transform: Utils.encodeTransform({
            translateX: offset + "px",
            translateY: String((parentModule.preview.height - h) / 2 + "px")
          })
        }
      }

      typos.push(typo)
      offset += w
    })

    return typos
  }

  static getCsvGalleryInitModules ({ parentModule }) {
    const typos = []
    const w = 1920
    const h = 1080
    let offset = 0;
    ["gallery"].forEach((time) => {
      const typo = {
        moduleName: Utils.ucfirst(time),
        parentModuleId: parentModule.uuid,
        type: SwiperGroupModuleType,
        skipSelection: true,
        skipHistory: true,
        uuid: uuidv4(),
        data: [],
        preview: {
          active: false,
          width: w,
          height: h,
          percentHeight: Utils.calculateRelativeValue(
            h,
            parentModule.preview.height
          ),
          percentWidth: Utils.calculateRelativeValue(
            w,
            parentModule.preview.width
          ),
          transform: Utils.encodeTransform({
            translateX: offset + "px",
            translateY: offset + "px"
          })
        }
      }

      typos.push(typo)
      offset += w
    })

    return typos
  }

  static getTimerTypoModules ({ parentModule }) {
    const typos = []
    const w = 200
    const h = 30
    let offset = (parentModule.preview.width - (Number(w))) / 2;
    ["timer"].forEach((time) => {
      const typo = {
        moduleName: Utils.ucfirst(time),
        parentModuleId: parentModule.uuid,
        type: TypoModuleType,
        skipSelection: true,
        skipHistory: true,
        uuid: uuidv4(),
        data: [
          {
            type: "typoText",
            value: "%%mm%%:%%ss%%",
            uuid: uuidv4()
          }
        ],
        preview: {
          active: false,
          width: w,
          height: h,
          percentHeight: Utils.calculateRelativeValue(
            h,
            parentModule.preview.height
          ),
          percentWidth: Utils.calculateRelativeValue(
            w,
            parentModule.preview.width
          ),
          transform: Utils.encodeTransform({
            translateX: offset + "px",
            translateY: String((parentModule.preview.height - h) / 2 + "px")
          })
        }
      }

      typos.push(typo)
      offset += w
    })

    return typos
  }

  static getCounterTypoModule ({ parentModule }) {
    const typos = []
    const w = 200
    const h = 30
    let offset = (parentModule.preview.width - (Number(w))) / 2;
    ["counter"].forEach((time) => {
      const typo = {
        moduleName: Utils.ucfirst(time),
        parentModuleId: parentModule.uuid,
        type: TypoModuleType,
        skipSelection: true,
        skipHistory: true,
        uuid: uuidv4(),
        data: [
          {
            type: "typoText",
            value: "%%counter_variable%%",
            uuid: uuidv4()
          }
        ],
        preview: {
          active: false,
          width: w,
          height: h,
          percentHeight: Utils.calculateRelativeValue(
            h,
            parentModule.preview.height
          ),
          percentWidth: Utils.calculateRelativeValue(
            w,
            parentModule.preview.width
          ),
          transform: Utils.encodeTransform({
            translateX: offset + "px",
            translateY: String((parentModule.preview.height - h) / 2 + "px")
          })
        }
      }

      typos.push(typo)
      offset += w
    })

    return typos
  }

  /**
   * Returns killSwitchDuration
   * @param duration
   */
  static getKillSwitchDuration (duration) {
    return duration === 0 ? 30 : duration
  }

  /**
   * Inserts element into array at given index
   * @param {array} list
   * @param {number} index
   * @param item
   */
  static insertAt (list, index, item) {
    list.splice(index, 0, item)
  }

  /**
   * Overrides global css variables
   * @param colors
   */
  static setShowroomCustomColors (colors) {
    Object.entries(colors).forEach(([key, value]) => {
      const elem = document.querySelector("body")
      const computedStyle = getComputedStyle(elem)
      const cssVariableName = `--${Utils.camelCaseToKebabCase(key)}`

      const cssVariableValue = computedStyle.getPropertyValue(cssVariableName)

      if (cssVariableValue !== value) {
        document.body.style.setProperty(cssVariableName, value)
      }
    })
  }

  static hasDifferentOrientationsVariants (variants) {
    let sameVariants = true

    let prevVariantPortrait = null
    variants.forEach((variant) => {
      const orientation = variant.dimensions[0] > variant.dimensions[1]

      if (prevVariantPortrait === null) {
        prevVariantPortrait = orientation
      }

      if (prevVariantPortrait !== orientation) {
        sameVariants = false
      }
    })

    return !sameVariants
  }
}
